import { Box, Typography } from "@mui/material";
import { useTheme } from "@cellectenergy/cellect-react-components";

import {
  ContactUsContainer,
  LeftColumn,
  RightColumn,
} from "./contactUs.styles";
import { useTranslation } from "react-i18next";
import { Form } from "../form/Form";

const ContactUs = () => {
  const theme = useTheme();
  const { t } = useTranslation("global");

  return (
    <ContactUsContainer
      gap={{ xs: "1.5rem", lg: "2rem" }}
      padding={{ xs: "2rem 1rem", md: "2rem 1.5rem", lg: "5rem 2rem" }}
      id="contactUs"
    >
      <Box
        maxWidth={"1440px"}
        display={"flex"}
        margin={"0 auto"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        gap={{ xs: "1.5rem", lg: "2rem" }}
      >
        <LeftColumn>
          <Typography
            variant="display-small"
            fontWeight={500}
            color={theme.palette.common.corporateColors.primary.white}
          >
            {t("contactUs.title")}
          </Typography>
          <Typography
            variant="title-large"
            fontWeight={400}
            color={theme.palette.common.corporateColors.primary.white}
          >
            {t("contactUs.description")}
          </Typography>
        </LeftColumn>
        <RightColumn>
          <Form />
        </RightColumn>
      </Box>
    </ContactUsContainer>
  );
};

export default ContactUs;
